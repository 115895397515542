import {
  TopNavButtonWithDropdown,
  TopNavDropdown,
  TopNavMenu,
  TopNavMenuItem,
  TopNavMenuLink,
} from 'alloy-foundation';
import { AuthUtils } from 'ui-auth-client';
import { memo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import { DraggableListItemProps } from 'alloy-foundation/es/components/DraggableList';
import { flushSync } from 'react-dom';
import { useDrawer } from '../drawer/DrawerProvider';
import DashboardSettingModal from './DashboardSettingModal';
import { useApplicationState } from '../common/providers/ApplicationStateProvider';
import { DashboardSettingItem } from '../../models/ApplicationState/DashboardSettingItem';
import { useProductInfoProvider } from '../productInfo/ProductInfoProvider';
import LoginService from '../../services/loginService/LoginService';

export const TILES_ITEM_ID = 'sectionTiles';
export const TODO_LIST_ITEM_ID = 'todoList';
export const TODO_LIST_WG_ITEM_ID = 'suspense';
export const EXPIRING_POLICIES_ITEM_ID = 'expiringPolicies';

export const initialItemList = [
  {
    id: TILES_ITEM_ID,
    title: <FormattedMessage id="dashboard.setting.sectionTiles" />,
    description: '',
    locked: true,
  },
  {
    id: TODO_LIST_ITEM_ID,
    title: <FormattedMessage id="dashboard.setting.todoList" />,
    description: '',
    disabled: false,
  },
  {
    id: TODO_LIST_WG_ITEM_ID,
    title: <FormattedMessage id="dashboard.setting.suspense" />,
    description: '',
    disabled: false,
  },
  {
    id: EXPIRING_POLICIES_ITEM_ID,
    title: <FormattedMessage id="dashboard.setting.expiringPolicies" />,
    description: '',
    disabled: false,
  },
];

interface Props {
  dashboardItemList: DraggableListItemProps[];
  saveDashboardSettings: (p: DashboardSettingItem[]) => void;
}

const TopNavMyAccountSection = () => {
  const { dashboardSettings, setDashboardSettings } = useApplicationState();
  const dashboardItemList = dashboardSettings?.map((p) => {
    const id = `dashboard.setting.${p.id}`;

    return {
      id: p.id,
      title: <FormattedMessage id={id} />,
      description: '',
      locked: p.locked,
      disabled: p.disabled,
    };
  });

  return (
    <InnerTopNavMyAccountSection
      dashboardItemList={dashboardItemList}
      saveDashboardSettings={setDashboardSettings}
    />
  );
};

/** InnerTopNavMyAccountSection is memoized to avoid unneccessary re-rendering when application state */
/** changes due to usage of useApplicationState() */
const InnerTopNavMyAccountSection = memo(({ dashboardItemList, saveDashboardSettings }: Props) => {
  const { hideDrawer } = useDrawer();
  const { getUserFullName } = useProductInfoProvider();
  const userName = getUserFullName();
  const { formatMessage } = useIntl();
  const [showSettingModal, setSettingModal] = useState(false);
  function handleToggle() {
    setSettingModal((prevState) => !prevState);
  }

  function handleClose() {
    setSettingModal(false);
  }
  return (
    <TopNavMenu>
      {showSettingModal && (
        <DashboardSettingModal
          initialList={dashboardItemList ?? initialItemList}
          handleClose={handleClose}
          saveDashboardSettings={saveDashboardSettings}
        ></DashboardSettingModal>
      )}
      <TopNavButtonWithDropdown
        aria-label="Open my account dropdown"
        iconName="topNavUser"
        title={formatMessage({ id: 'topnav.my_account.my_account' })}
        variant="dark"
        data-cy="MyAccount"
        data-testid="MyAccount"
        onClick={() => flushSync(() => hideDrawer())}
      >
        {userName}
      </TopNavButtonWithDropdown>
      <TopNavDropdown alignment="right">
        <TopNavMenuLink data-cy="TopNavMyAccount" as={RouterLink} to="/myprofile">
          <FormattedMessage id="topnav.my_account.my_profile" />
        </TopNavMenuLink>
        <TopNavMenuLink data-cy="TopNavDashboardSettings" onClick={handleToggle}>
          <FormattedMessage id="topnav.my_account.dashboard_settings" />
        </TopNavMenuLink>
        <TopNavMenuItem
          onSelect={async () => {
            try {
              await LoginService.Logout();
              // Catch needs to be empty in this case
              // eslint-disable-next-line no-empty
            } catch {}
            AuthUtils.logout();
          }}
          data-testid="LogoutLink"
        >
          <FormattedMessage id="topnav.my_account.logout" />
        </TopNavMenuItem>
      </TopNavDropdown>
    </TopNavMenu>
  );
});

export default TopNavMyAccountSection;
