import baseRequests from '../../api/baseRequests';
const path = 'api/Login';

const LoginService = {
  GetVerification: (): Promise<void> =>
    baseRequests.get(`${path}/GetVerification`).then((response) => response),
  SelectAgency: (selectedAgencyId: string, selectedGroupId: number) =>
    baseRequests.post(`${path}/SelectAgency`, { selectedAgencyId, selectedGroupId }),
  Logout: () => baseRequests.get(`${path}/logout`),
  //   list: (criteria: ToDoListCriteria): Promise<ToDoList> =>
  //     baseRequests.post(`${baseURL}/GetDoToList`, criteria).then((response) => response),
  //   getOptionsDomainData: (): Promise<ToDoListOptionsDomainData> =>
  //     baseRequests.get(`${baseURL}/ViewOptionsDomainData`).then((response) => response),
  //   updateSuspenseStatus: (
  //     criteria: ToDoListCompleteStatusCriteria
  //   ): Promise<ToDoListCompleteStatusResult> =>
  //     baseRequests.post(`${baseURL}/CompleteSuspenseStatus`, [criteria]).then((response) => response),
};

export default LoginService;
